import { Injectable } from "@angular/core";
@Injectable()
export class Permission {
  public ChargesVsPayment: boolean;
  public RCMWaterfall: boolean;
  public RevenueCycle: boolean;
  public ExpectedCollection: boolean;
  //new
  public CPTExpectedCollection: boolean;
  public CPTAllowedvsASPPricing: boolean;
  public Acountrecivable: boolean;
  public QPMholddashboard: boolean;
  public DenialAnalysis: boolean;
  public DenialsHistory: boolean;
  public DenialManagement: boolean;
  public PanelPayerDistribution: boolean;
  public LocationFinancialPayment: boolean;
  public paneldistribution: boolean;
  public PracticeCompensation: boolean;
  public RCMDayWisePayerPayment: boolean;
  public ProviderCompensation: boolean;

  public ProviderConfigurationbyPOS: boolean;
  public ClaimNotWorkedUpon: boolean;
  public AcountrecivableinDash: boolean;
  public PatientPayment: boolean;
  public DailyChargeDetails: boolean;
  public ClaimAtRisk: boolean;
  public TimelyFiling: boolean;
  public CPTAnalysis: boolean;
  public chargebatchLag: boolean;
  public KeyPerformance: boolean;
  public ExamFinishLag: boolean;
  public Metric: boolean;
  public AgedPayments: boolean;

  public ProjectInsight: boolean;
  public MyProjects: boolean;

  // biotech
  public IncompleteOrder: boolean;
  public BatchDistribution: boolean;
  public BiotechConfiguration: boolean;
  public Insurancefollowup: boolean;
  public Trizetto: boolean;
  // public BiotechDayWisePayerPayment: boolean;
  public RejectionRate: boolean;
  public Biotechproductivity: boolean;




  // banq
  public ERAClientDistribution: boolean;
  public ERADaywiseDistribution: boolean;
  public ERADivisionDistribution: boolean;
  public checkdistribution: boolean;
  public filedistribution: boolean;
  public honestreport: boolean;
  public newfiledistribution: boolean;
  public practicesummary: boolean;
  public DayWisePayerPayment: boolean;
  public mhphubreport: boolean;

  //Metrics
  public keyMetrics: boolean;
  public ChargesAnalysis: boolean;
  public RefundAnalysis: boolean;
  public TakebackAnalysis: boolean;
  public paymentAnalysis: boolean;
  public DailyTracker: boolean;
  public PaymentBreakUP: boolean;
  public Paymentwaterfall: boolean;
  public OverallARAndbreakup: boolean;
  public InsuranceDebitARAging: boolean;
  public ChemononChemo: boolean;
  public ProviderYTD: boolean;
  public RenderingProviderYTD: boolean;
  public ProviderCharges: boolean;
  public ProviderPayments: boolean;
  public InsuranceARComparision: boolean;
  public Financialproductivity: boolean;
  public ProviderProductivity: boolean;
  // public FinancialAR: boolean;

  //value
  public orthoLowerSummary: boolean;
  public PostUtilization: boolean;
  public ReconcilationUtilization: boolean;
  public Ortholowersummary: boolean;
  public OrtholowerIPRiskStratified: boolean;
  public OrtholowerFractures: boolean;
  public OrtholowerReadmissions: boolean;
  public Orthouppersummary: boolean;
  public OrthoupperIPRiskStratified: boolean;
  public OrthoupperReadmissions: boolean;
  public UtilizationComparison: boolean;
  public ReconciliationPerformance: boolean;
  public ActualReconciliation: boolean;
  public BCBSCommercialActual: boolean;
  public BCBSUtilizations: boolean;

  //Admin
  public QAdminPracticeConfiguration: boolean;
  public QAdminBillingConfiguration: boolean;
  public QAdminDayWisePayerPayment: boolean;
  public ProviderConfiguration: boolean;
  public DefaultdashboardConfig: boolean;
  public DefaultdashboardConfigAdmin: boolean;
  public CustomizedDashboard: boolean;
  public ModelConfiguration: boolean;
  //Adhoc
  public MRIDetails: boolean;




  public ProviderCompensationbyPOS: boolean;
  ExpectedValueConfig: boolean;




  // clinical
  public Covid: boolean;
  public QualityDashboard2021: boolean;
  public QualityDashboard2021PatientSummary: boolean;
  patienttracking: boolean;
  //QBOT
  public dashboardloadingtime: boolean;
  public dashboardupdatedtime: boolean;
  public qbotdashboard: boolean;
  //monitor
  public Services: boolean;
  public productusage: boolean;

  DenialConfig: boolean;
  // biotechproductivity: boolean;
  MissedOpportunities: boolean;
  MissingCharges: boolean;
  MonthlyCharges: boolean;
  internaluserconfiguration: boolean;
  MonthlyClose: boolean;
  MtdYtdReport: boolean;
  DailyCharges: boolean;
  DailyClose: boolean;
  MonthlyPayment: boolean;
  //Value
  facilityanalytics: boolean;
  hhaanalytics: boolean;
  snfanalytics: boolean;
  ptanalytics: boolean;
  alliancesummaryanalytics: boolean;
  practicesummaryanalytics: boolean;
  orderanalytics: boolean;
  maintenance: boolean;

  //RCM
  public allrcmProductivity: boolean;
  rcmProductivity: boolean;
  public dellclaimexport: boolean;
  public rcmanalytics: boolean;
  public chargeliquidation: boolean;
  public availablereserve: Boolean;
  public appointcensusreport: boolean;
  public OverallpatientMetrics: boolean;
  TFLUserConfig: boolean;
  automailconfig: Boolean;

  //Value
  chargesAnalysisMIU: any;
  paymentAnalysisMIU: any;
  keyMetricsMIU: any;
  MIUproductivity: boolean;
  ExpectedConfigMIU: boolean;
  RCMUserConfig: boolean;
  MIUdailyTracker: boolean;
  ARMIUanalysis: boolean;
  miufinancialprod: boolean
  MiuDenialManagement: boolean
  appointment: boolean;
  erapaymentposting: boolean
  Insurancependingchecks: boolean
  //permissions need to add
  AutomationConfiguration: boolean;
  automationconfigedit: boolean;
  // ModelConfiguration:boolean;
  Projects: boolean;
  ValueConfig: boolean;
  home: boolean;


  //configuration dashboard 
  public serviceconfig: boolean;
  public serviceconfigAdd: boolean;   //to add new practice in Config and it is saved in session storage
  public serviceconfigDelete: boolean;
  public serviceconfigUpdate: boolean;
  public serviceconfiggroup: boolean;
  public PracticeUpdate: boolean;

  public AddDenialConfig: boolean;
  denialepayment: boolean;
  forecastalowedpayment: boolean;
  surgicalordertracking: any;





}





@Injectable()
export class ServiceConfigPermission {
  //SERVICE CONFIG
  public serviceconfig: boolean;
  public serviceconfigAdd: boolean;
  public serviceconfigDelete: boolean;
  public serviceconfigUpdate: boolean;
  public serviceconfiggroup: boolean;
  public PracticeUpdate: boolean;
}
